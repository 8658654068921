
export const useCampaignsStore = defineStore('campaigns', {
  state: () => ({
    campaigns: [],
  }),
  getters: {},
  actions: {
    SET_CAMPAIGNS (campaigns) {
      this.campaigns = [ ...campaigns ]
    },
    async fetchPromoted () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/campaigns/promoted`)
        this.SET_CAMPAIGNS(shuffleArray(result))

        return shuffleArray(result)
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('campaigns: Error fetching campaigns', {
          level: 'warning',
          contexts: {
            details: err.data || err,
          },
          tags: {
            type: 'UX',
          },
        })
        return null
      }
    },

    async fetchAll () {
      const localeStore = useLocaleStore()
      const locale = localeStore.locale

      try {
        const { data: result } = await apiFetch(`/${locale}/campaigns/all`)
        return result
      } catch (err) {
        const { $sentryCaptureMessage } = useNuxtApp()

        $sentryCaptureMessage('campaigns: Error fetching all campaigns', {
          level: 'warning',
          contexts: {
            details: err.data || err,
          },
          tags: {
            type: 'UX',
          },
        })
        return null
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCampaignsStore, import.meta.hot))
}
